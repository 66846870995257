<template>
  <div class="popup_wrap popup_wrap_pdf" style="width:1000px;">
    <button type="button" class="layer_close" @click="$emit('close')">close</button>
    <div class="popup_cont">
      <h1 class="page_title">{{ $t('msg.REPORT_PDF.120') }}<!-- Proforma Invoice --></h1>
      <div class="content_box">
        <div class="clearfix">
          <div class="float_left col_3">
            <table class="tbl_list font_sm gridtype">
              <colgroup>
                <col width="100%">
              </colgroup>
              <thead>
                <tr>
                  <th>{{ $t('msg.REPORT_PDF.002') }}<!-- B/L No. --></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ param.blNo }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="float_left text_center col_7 pl20" style="max-height:500px;overflow-y:auto">
            <!-- <div>{{ pdf.currentPage }} / {{ pdf.pageCount }}</div> -->
            <vue-pdf-app
              style="height: 300px;"
              :pdf="pdf.pdfUrl"
              :config="pdfConfig.config"
              :id-config="pdfConfig.idConfig"
              theme="light"
            ></vue-pdf-app>
            <div class="paging print">
              <span class="num">
                <input type="number" :id="pdfConfig.idConfig.pageNumber" class="pageNumber" maxlength="2">
                <span :id="pdfConfig.idConfig.numPages" class="pageNumber">-</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="mt10 text_center">
        <a class="button blue lg" href="#" @click.prevent="fnDownlaod();">{{ $t('msg.REPORT_PDF.101') }}<!-- 다운 --></a>
        <a v-show="openPrint" class="button blue lg" ref="pdfPrint" :id="pdfConfig.idConfig.print">{{ $t('msg.REPORT_PDF.102') }}<!-- 인쇄 --></a>
        <a v-show="!openPrint" class="button blue lg" @click="openPopup()">{{ $t('msg.REPORT_PDF.102') }}<!-- 인쇄 --></a>
        <a class="button blue lg" href="#" @click.prevent="openSendEmailPopup();">{{ $t('msg.REPORT_PDF.103') }}<!-- 공유 --></a>
      </div>
    </div>

    <!-- <iframe id="iframe1" :src="pdf.pdfUrl" frameborder="0" style="display:block;width:100%;height:100%"></iframe> -->

    <win-layer-pop class="pdf_send_email">
      <component
        v-if="customComponent"
        :is="customComponent"
        @close="closeSendEmailPopup"
        :parent-info="sendEmailParam"
      />
    </win-layer-pop>
  </div>
</template>

<script>
import { rootComputed } from '@/store/helpers'
import commonPdf from '@/plugins/commonPdf'
import 'vue-pdf-app/dist/icons/main.css'

const REPORT_URL = process.env.VUE_APP_REPORT_URL
const SETTLE_URL = process.env.VUE_APP_SETTLE_URL

export default {
  name: 'ProformaInvoicePop',
  components: {
    vuePdfApp: () => import('vue-pdf-app'),
    WinLayerPop: () => import('@/pages/trans/popup/WinLayerPop'),
    PdfSendEmailPop: () => import('@/pages/pdf/popup/PdfSendEmailPop')
  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      customComponent: null,
      sendEmailParam: {},
      items: [],
      userInfo: {},
      pdf: {
        pdfUrl: '',
        emailUrl: '',
        emailFiles: [],
        pg: 1,
        pageCount: 0,
        currentPage: 0
      },
      pdfConfig: {
        config: {},
        idConfig: {}
      },
      param: {
        demDetCatCd: '01',
        calExts: 'BMOU6210963_20211119_0_0_undefined',
        eiCatCd: 'O',
        blNo: 'PUSE020438',
        invIssNo: ''
      },
      openPrint: false
    }
  },
  computed: {
    ...rootComputed
  },
  watch: {
    parentInfo () {
      if (this.$props.parentInfo.blNo !== undefined) {
        this.setItems(this.$props.parentInfo)
      }
    }
  },
  created () {
    this.init()
    console.log('# auth info : ', this.auth)
    console.log('# auth memberDetail : ', this.memberDetail)
  },
  methods: {
    async init () {
      this.pdfConfig = commonPdf.getPdfConfig()

      // 로그인 여부 체크
      if (!this.auth.userId) {
        await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.REPORT_PDF.115') }) // 로그인을 확인해주세요.
        this.$emit('close')
        return
      }

      if (this.$props.parentInfo.blNo !== undefined) {
        this.setItems(this.$props.parentInfo)
      }

      commonPdf.commonInit()
    },
    async setItems (items) {
      let userId = this.auth.userId
      let orgUserId = this.auth.orgUserId

      let serviceLang = this.auth.serviceLang
      this.param.blNo = items.blNo
      this.pdf.pdfUrl = SETTLE_URL + '/settle/invoice-print?blNo=' + items.blNo + '&calExts=' + items.calExts + '&demDetCatCd=' + items.demDetCatCd + '&eiCatCd=' + items.eiCatCd + '&invIssNo=' + items.invIssNo + '&userId=' + userId + '&orgUserId=' + orgUserId + '&serviceLang=' + serviceLang
      this.pdf.emailUrl = REPORT_URL + '/report/email/proforma-invoice?blNo=' + items.blNo + '&calExts=' + items.calExts + '&demDetCatCd=' + items.demDetCatCd + '&eiCatCd=' + items.eiCatCd + '&invIssNo=' + items.invIssNo + '&userId=' + userId + '&serviceLang=' + serviceLang
      this.pdf.emailFiles = [items.blNo + '.pdf']
      //'&ctrCd=' + ctrCd + '&bkgPlcCd=' + bkgPlcCd + '&compEname=' + userEname +
      console.log('# this.pdf : ', this.pdf)
    },
    fnDownlaod () {
      console.log('fnDownlaod')
      console.log(this.pdf.pdfUrl)
      commonPdf.download(this.pdf.pdfUrl, 'KMTC' + this.param.blNo + '.pdf')
    },
    openSendEmailPopup () {
      this.sendEmailParam = {
        emailUrl: this.pdf.emailUrl,
        emailFiles: this.pdf.emailFiles,
        type: 'AN'
      }
      this.customComponent = 'PdfSendEmailPop'
      commonPdf.layerOpen('.pdf_send_email')
    },
    closeSendEmailPopup () {
      this.customComponent = null
      commonPdf.layerClose('.pdf_send_email')
    },
    openPopup () {
      const confirmOpt = {
        alertType: 'simple',
        customCloseBtnText: this.$t('msg.ARAI200.007'), // 출력
        customConfirmBtnText: this.$t('msg.ONEX050P150.005'), // 바로가기
        useConfirmBtn: true,
        message: this.$t('msg.REPORT_PDF.200'), // 출력한 컨테이너 및 반출일자로 입금계산서 정보입력 진행하시겠습니까?
        onConfirm: this.onConfirmPopup,
        onClose: this.onClosePopup
      }

      this.$ekmtcCommon.alert(confirmOpt)
    },
    onConfirmPopup () {
      // this.openPrint = true
      // this.$emit('close')

      this.$emit('child', {
        type: 'openPopup',
        payload: {
          compNm: 'InputTaxInvoiceInfo',
          idx: this.$props.parentInfo.idx
        }
      })
    },
    onClosePopup () {
      // this.openPrint = true
      this.$refs.pdfPrint.click()
    }
  }
}
</script>

<style scoped>

body {
  min-width:100% !important;
  overflow: auto !important;
}

@media print{
  html, body {
    size:210mm 297mm; /*A4*/
    transform:scale(0.98, 0.98);
  }
}

</style>
